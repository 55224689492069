import React, { lazy, Suspense, useEffect, useState } from 'react'
import { AuthContext, checkAuthContextAndGetActual, OnlyAuthenticatedRoute, OnlyUnauthenticatedRoute, unauthenticatedUser } from './auth'
import { BrowserRouter as Router, Redirect, Switch, Route, useLocation } from 'react-router-dom'
import { Loader } from './Loader'
import { cssTransition, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { useTranslation } from 'react-i18next'
import { languages } from '../i18n'

const MedicalApp = lazy(() => import('./app/index'))
const SignIn = lazy(() => import('./sign-in/index'))
const SignUp = lazy(() => import('./sign-up/index'))
const DemoApp = lazy(() => import('./demo/index'))

function ChangeLanguage({ authContext }) {
    const { i18n } = useTranslation()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const urlLang = params.get('lang')

    useEffect(() => {
        const rootHtml = document.getElementById('root-html')

        // Приоритетно используем язык из URL
        if (urlLang && Object.keys(languages).includes(urlLang)) {
            rootHtml.setAttribute('dir', languages[urlLang].direction)
            if (i18n.resolvedLanguage !== urlLang) {
                i18n.changeLanguage(urlLang)
            }
            return
        }

        // Если нет языка в URL, используем язык из настроек
        if (authContext.settings?.lang) {
            rootHtml.setAttribute('dir', languages[authContext.settings.lang].direction)
            if (i18n.resolvedLanguage !== authContext.settings.lang) {
                i18n.changeLanguage(authContext.settings.lang)
            }
        }
    }, [authContext.settings, i18n, urlLang])

    return null
}

async function migrateTokenIfNeeded(setAuthContext) {
    const storedAuth = localStorage.getItem('authContext')
    if (storedAuth) {
        const authContext = JSON.parse(storedAuth)
        if (authContext.token) {
            document.cookie = `key=${authContext.token}; path=/; secure; samesite=strict`
            const newAuthContext = await checkAuthContextAndGetActual()
            setAuthContext(newAuthContext)
            localStorage.removeItem('authContext')
        }
    }
}

export function MainComponent() {
    const [authContext, setAuthContext] = useState(unauthenticatedUser)
    const [isInitialized, setIsInitialized] = useState(false)

    useEffect(() => {
        const initAuth = async () => {
            await migrateTokenIfNeeded(setAuthContext)
            const actualAuthContext = await checkAuthContextAndGetActual()
            setAuthContext(actualAuthContext)
            setIsInitialized(true)
        }
        initAuth()
    }, [])

    const bounce = cssTransition({
        enter: 'slide-in-right',
        exit: 'slide-out-right'
    })

    if (!isInitialized) {
        return <Loader />
    }

    return (
        <AuthContext.Provider value={{ user: authContext, setUser: setAuthContext }}>
            <Router>
                <Suspense fallback={<Loader />}>
                    <ChangeLanguage authContext={authContext} />
                    <Switch>
                        <Route path='/demo' component={DemoApp} />
                        <OnlyUnauthenticatedRoute path='/sign-in' component={SignIn} />
                        <OnlyUnauthenticatedRoute path='/sign-up' component={SignUp} />
                        <OnlyAuthenticatedRoute path='/app' component={MedicalApp} />
                        <Redirect to='/sign-in' />
                    </Switch>
                </Suspense>
                <ToastContainer
                    position='bottom-right'
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    transition={bounce}
                />
            </Router>
        </AuthContext.Provider>
    )
}
